@use '@angular/material' as mat;

$custom-typography: mat.define-typography-config(
  $font-family: "Poppins-regular"
);
@include mat.all-component-typographies($custom-typography);
@include mat.core();

$purple-palette: (
 50: #f4e6f2,
 100: #e3c0e0,
 200: #d296cd,
 300: #bf6eb8,
 400: #b04fa9,
 500: #a1359b,
 600: #953095,
 700: #832a8d,
 800: #742584,
 900: #581D74,
 contrast: (
  50 : #000000,
  100 : #000000,
  200 : #000000,
  300 : #000000,
  400 : #ffffff,
  500 : #ffffff,
  600 : #ffffff,
  700 : #ffffff,
  800 : #ffffff,
  900 : #ffffff,
  A100 : #000000,
  A200 : #000000,
  A300 : #000000,
  A700 : #000000,
 )
);

$natixis-qdi-front-primary: mat.define-palette($purple-palette, 900);
$natixis-qdi-front-accent: mat.define-palette(
  $purple-palette,
  300,
  600,
  900
);

$natixis-qdi-front-warn: mat.define-palette(mat.$red-palette);
$natixis-qdi-front-theme: mat.define-light-theme(
  (
    color: (
      primary: $natixis-qdi-front-primary,
      accent: $natixis-qdi-front-accent,
      warn: $natixis-qdi-front-warn
    )
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($natixis-qdi-front-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  margin: 0;
  padding: 0 !important;
}
