@import "natixis-theme";
@import "~material-icons/iconfont/material-icons.css";

@font-face {
  font-family: "Poppins-regular";
  src: url("./assets/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins-semibold";
  src: url("./assets/fonts/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Montserrat-medium";
  src: url("./assets/fonts/Montserrat-Medium.ttf");
}

html,
body {
  font-family: "Poppins-regular";
  height: 100%;
  margin: 0;
}
